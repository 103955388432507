import axios from "axios";
import apiURL from "../../config/urlconfig";

const requests = {
  namespaced: true,
  state: {
    hasSearched: false,
    requestBody: "",
    requestCode: "",
    requestHeader: "",
    searchTerm: "",
    searchResults: [],
    topten: [],
    searchLoading: false,
    requestLoading: false,
    recentSearches: [],
    // requestServiceIsDown: false,
  },
  mutations: {
    SET_TOP_TEN(_state, _array) {
      _state.topten = _array;
    },
    SET_HAS_SEARCHED(_state, _status) {
      _state.hasSearched = _status;
    },
    SET_REQUEST_ERROR(_state, _errorMessage) {
      _state.requestError = _errorMessage;
    },
    SET_REQUEST_STRINGS(_state, _requestStrings) {
      _state.requestBody = _requestStrings.body;
      _state.requestHeader = _requestStrings.header;
      _state.requestCode = _requestStrings.code;
    },
    SET_SEARCH_TERM(_state, _searchTerm) {
      _state.searchTerm = _searchTerm;
    },
    SET_SEARCH_RESULTS(_state, _searchResults) {
      _state.searchResults = _searchResults;
    },
    SET_SEARCH_LOADING(_state, _status) {
      _state.searchLoading = _status;
    },
    SET_REQUEST_LOADING(_state, _status) {
      _state.requestLoading = _status;
    },
    SET_RECENT_SEARCHES(_state, _recentSearches) {
      _state.recentSearches = _recentSearches;
    },
  },
  actions: {
    setRecentSearches({ commit }, value) {
      commit("SET_RECENT_SEARCHES", value);
    },
    setSearchResults({ commit }, results) {
      commit("SET_SEARCH_RESULTS", results);
    },
    setHasSearched({ commit }, value) {
      commit("SET_HAS_SEARCHED", value);
    },
    setSearchLoading({ commit }, value) {
      commit("SET_SEARCH_LOADING", value);
    },
    setSearchTerm({ commit }, value) {
      commit("SET_SEARCH_TERM", value);
    },
    setTopTen({ commit }, value) {
      commit("SET_TOP_TEN", value);
    },
    fetchSongs({ dispatch }, { term, admin, field }) {
      dispatch("setSearchLoading", true);
      const url = `${apiURL}/api/findall`;
      axios
        .post(url, { search: term, admin, field })
        .then((res) => {
          if (res?.data && Array.isArray(res.data)) {
            dispatch("setSearchResults", res.data);
            dispatch("setHasSearched", true);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          dispatch("setSearchLoading", false);
        });
    },
    makeRequest({ commit, state, rootState, rootGetters }, { trackid, searchTerm, playNext }) {
      commit("SET_REQUEST_LOADING", true);
      commit("SET_REQUEST_STRINGS", { body: "", header: "", code: "" });
      let success = false;
      let headerObj = {};
      if (rootGetters["admin/authToken"].length > 10) {
        headerObj = { headers: { authorization: rootGetters["admin/authToken"] } };
      }
      const url = `${apiURL}/api/request`;
      const reqeustPayload = { trackid, searchTerm };
      if (playNext) reqeustPayload.playNext = playNext;
      // fix this to account for new {message: ...} response instead of header, body, code
      axios
        .post(url, reqeustPayload, headerObj)
        .then((res) => {
          const { code, message } = res.data;
          const requestHeader = code === "200" ? "Request Successful" : "Request Failed";
          const requestBody = code === "200" ? "Keep listening to hear this song!" : message;
          const requestCode = code;
          const requestStrings = {
            body: requestBody,
            header: requestHeader,
            code: requestCode,
          };
          if (code === "200") {
            success = true;
            const now = new Date();
            const searchTermExpire = now.getTime() + 10800000;
            const recentRequestObj = {
              search: searchTerm,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              expiry: searchTermExpire,
            };
            const notGunnaDoIt = state.recentSearches.some(
              (term) => term.search === recentRequestObj.search
            );
            const inFavorites = rootState.mySongs.favorites.some(
              (song) => song.trackid === trackid
            );
            if (!notGunnaDoIt && !inFavorites) {
              const recentSearches = [...state.recentSearches, recentRequestObj];
              commit("SET_RECENT_SEARCHES", recentSearches);
            }
            commit("SET_SEARCH_TERM", "");
            commit("SET_SEARCH_RESULTS", []);
            commit("SET_HAS_SEARCHED", false);
            commit("SET_REQUEST_STRINGS", requestStrings);
            return { success };
          } else {
            commit("SET_REQUEST_STRINGS", requestStrings);
            return { success };
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          return { success: false };
        })
        .finally(() => {
          commit("SET_REQUEST_LOADING", false);
          commit("SET_SEARCH_TERM", "");
          commit("SET_SEARCH_RESULTS", []);
          setTimeout(() => commit("SET_REQUEST_STRINGS", { body: "", header: "", code: "" }), 2000);
        });
    },
    getTopRequested({ dispatch }, { field }) {
      const url = `${apiURL}/api/toprequests`;
      axios
        .post(url, { field })
        .then((res) => {
          dispatch("setTopTen", res.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  },
};

export default requests;
